<script lang="ts" setup>
import type { StaticPagesListItem } from '~/types/StaticPageList';

const props = withDefaults(defineProps<{ item: StaticPagesListItem; isShowAnnotation?: boolean }>(), {
  isShowAnnotation: true,
});

const dayjs = useDayjs();
const formattedDate = dayjs(props.item.publication_date).format('DD MMMM YYYY');
</script>

<template>
  <div class="static-page-list-item">
    <picture
      v-if="item.image?.url"
      class="static-page-list-item__image"
    >
      <source
        :srcset="item.image?.conversions_webp.thumb_390"
        type="image/webp"
      />

      <source
        :srcset="item.image?.conversions.thumb_390"
        type="image/jpeg"
      />

      <img
        :alt="item.image?.properties?.title"
        :src="item.image?.url"
        class="w-full h-full object-contain aspect-video"
        loading="lazy"
      />
    </picture>

    <div
      v-else
      class="static-page-list-item__no-image"
    />

    <div class="static-page-list-item__info">
      <div class="font-bold">{{ item.name }}</div>

      <div
        v-if="isShowAnnotation"
        class="flex-grow text-hell-black-80"
      >
        {{ item.annotation }}
      </div>

      <div class="text-hell-black-60 text-xs">{{ formattedDate }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.static-page-list-item {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: $white;
  box-shadow: 0 0 16px rgb(0 0 0 / 5%);
  transition: box-shadow 0.3s;

  &__image {
    object-fit: contain;
    aspect-ratio: 16/9;
  }

  &__no-image {
    width: 100%;
    background:
      linear-gradient(217deg, rgba($hell-black, 0.8), rgba($hell-black, 0) 70.71%),
      linear-gradient(127deg, rgba($hell-black-60, 0.8), rgba($hell-black-60, 0) 70.71%),
      linear-gradient(336deg, rgba($black, 0.8), rgba($black, 0) 70.71%);
    aspect-ratio: 16/9;
  }

  &__info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    padding: 20px;
    font-size: 14px;
  }
}
</style>
